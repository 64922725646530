<template>
  <div class="px-5">
    <Form
      title="Crear categoría"
      ref="categoryForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.categoryForm.form
      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: {
            name: form.name,
            active: !this.visible ? 0 : 1
          },
          route: "/master/category/save",
          module: "category"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/categorias")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
