<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombre'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.name"
                name="name"
                placeholder="Ingrese el nombre"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1 relative">
            <div class="flex justify-start md:ml-2 md:mr-2 my-4">
              <p>Mostrar en la app</p>
              <t-toggle
                class="mx-3"
                v-model="visible"
                @click="toggleVisibility(!visible)"
                :disabled="loading"
              />
              <p>Sí</p>
            </div>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar categoría</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        name: null
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente la categoría.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/master/category",
                module: "category"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/categorias")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {}
}
</script>
